import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueHeroGrid/InteractiveLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/MenuTerms/MenuTerms.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/PushNotifications/PushNotification.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Footer/Footer.module.scss");
